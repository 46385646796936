body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
 }

 a, u {
  text-decoration: none;
  color: black;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@document url-prefix("http://www.sunsetsolar.us/solarform" || "http://localhost:3000//solarform") {
    #header
    {
        visibility: hidden !important;
    }
  }
  
.lead-form-1 {
  align-items: flex-start;
  display: flex;
  height: 650px;
  margin-left: 74.0px;
  margin-top: 79px;
  min-width: 656px;
}

.overlap-group2-2 {
  align-items: flex-start;
  background-color: var(--navy-blue);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  min-height: 650px;
  padding: 27.2px 53px;
  width: 644px;
}

.leave-your-info-to-schedule-a-call-1 {
  letter-spacing: 0;
  min-height: 99px;
  text-align: center;
  width: 537px;
}

.overlap-group5-1 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  margin-top: 39px;
  min-width: 537px;
}

.place-1 {
  height: 58px;
  letter-spacing: 0;
  width: 537px;
}

.overlap-group-container-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 43px;
  min-width: 537px;
}

.overlap-group4-1 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  min-width: 259px;
}

.email-1 {
  height: 58px;
  letter-spacing: 0;
  width: 259px;
}

.overlap-group-3 {
  height: 60px;
  margin-left: 19px;
  position: relative;
  width: 259px;
}

.rectangle-4-1 {
  background-color: var(--white);
  border: 1px none;
  height: 58px;
  left: 0;
  position: absolute;
  top: 0;
  width: 259px;
}

.phone-2 {
  height: 58px;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 2px;
  width: 259px;
}

.overlap-group1-3 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  margin-top: 43px;
  min-width: 537px;
}

.message-1 {
  height: 115px;
  letter-spacing: 0;
  width: 537px;
}

.overlap-group3-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 15px;
  display: flex;
  margin-top: 43px;
  min-width: 537px;
}

.book-a-consultation-1 {
  height: 58px;
  letter-spacing: 0;
  text-align: center;
  width: 537px;
}

.lead-form-1.lead-form-2 {
  margin-left: 12.0px;
  margin-top: 55px;
}


.desktop {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 3800px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.flex-col {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: -480px;
  min-height: 1106px;
  width: 2014px;
}

.overlap-group6-1 {
  align-self: flex-end;
  height: 831px;
  position: relative;
  width: 1920px;
}

.first-section-1 {
  background-color: var(--navy-blue);
  border: 1px none;
  height: 830px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.save-money-and-own-y-1 {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: 26px;
  font-weight: 400;
  left: 42px;
  letter-spacing: 0;
  position: absolute;
  text-align: center;
  top: 218px;
  width: 723px;
}

.solar-energy-1 {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: 48px;
  font-weight: 400;
  left: 232px;
  letter-spacing: 0;
  position: absolute;
  text-align: center;
  top: 278px;
}

.overlap-group-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 15px;
  display: flex;
  height: 46px;
  justify-content: flex-end;
  left: 185px;
  min-width: 401px;
  position: absolute;
  top: 369px;
}

.book-consultation-1 {
  height: 46px;
  letter-spacing: 0;
  text-align: center;
  width: 399px;
}

.sign-up-for-a-free-c-1 {
  height: 44px;
  left: 97px;
  letter-spacing: 0;
  position: absolute;
  text-align: center;
  top: 439px;
  width: 582px;
}

.funnel-person-2-png-1-1 {
  height: 612px;
  left: 720px;
  object-fit: cover;
  position: absolute;
  top: 219px;
  width: 555px;
}

.during-this-free-pre-1 {
  letter-spacing: 0;
  margin-top: 62px;
  min-height: 44px;
  text-align: center;
  width: 1600px;
}

.flex-row {
  align-items: center;
  align-self: center;
  display: flex;
  height: 116px;
  margin-right: 357.0px;
  margin-top: 53px;
  min-width: 945px;
}

.money-symbol-paid-1-1 {
  align-self: flex-start;
  height: 102px;
  object-fit: cover;
  width: 102px;
}

.icon-key-2 {
  height: 102px;
  margin-left: 336px;
  object-fit: cover;
  width: 102px;
}

.tax-receipt-icon-1-2 {
  height: 102px;
  margin-left: 303px;
  object-fit: cover;
  width: 102px;
}

.flex-row-1 {
  align-items: flex-end;
  display: flex;
  height: 54px;
  margin-left: 19.0px;
  margin-top: 30px;
  min-width: 1217px;
}

.cost-savings-1 {
  letter-spacing: 0;
  min-height: 48px;
  text-align: center;
  width: 364px;
}

.ownership-2 {
  letter-spacing: 0;
  margin-left: 84px;
  min-height: 48px;
  text-align: center;
  width: 364px;
}

.tax-savings-2 {
  align-self: flex-start;
  letter-spacing: 0;
  margin-left: 41px;
  min-height: 48px;
  text-align: center;
  width: 364px;
}

.overlap-group8 {
  height: 981px;
  margin-top: 34px;
  position: relative;
  width: 1440px;
}

.well-discuss-how-so-1 {
  left: 150px;
  letter-spacing: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 282px;
}

.overlap-group7-1 {
  height: 978px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 1440px;
}

.why-homeowners-are-c-2 {
  left: 610px;
  letter-spacing: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 282px;
}

.marble-background-image-1 {
  height: 703px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 225px;
  width: 1440px;
}

.meet-john-section-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 477px;
  min-height: 610px;
  position: absolute;
  top: 368px;
  width: 836px;
}

.title {
  color: transparent;
  font-family: var(--font-family-open_sans);
  font-size: 52px;
  font-weight: 700;
  letter-spacing: 0;
  min-height: 71px;
  text-align: center;
  width: 609px;
}

.span0 {
  color: var(--black);
}

.span1-2 {
  color: var(--navy-blue);
}

.overlap-group1-2 {
  align-self: center;
  height: 84px;
  margin-right: 79.0px;
  margin-top: 15px;
  position: relative;
  width: 593px;
}

.solar-professional-1 {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: 48px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 593px;
}

.line-1-1 {
  height: 4px;
  left: 90px;
  position: absolute;
  top: 80px;
  width: 144px;
}

.x5-years-experience-1 {
  align-self: flex-end;
  letter-spacing: 0;
  margin-right: 6px;
  margin-top: 43px;
  min-height: 397px;
  width: 694px;
}

.business-man-suit-png-1-1 {
  height: 580px;
  left: 282px;
  object-fit: cover;
  position: absolute;
  top: 348px;
  width: 353px;
}

.how-you-can-apply-yo-2 {
  left: 1022px;
  letter-spacing: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 282px;
}

.going-green-has-never-been-this-easy-1 {
  letter-spacing: 0;
  margin-left: 62.0px;
  margin-top: 52px;
  min-height: 49px;
  text-align: center;
  width: 714px;
}

.solar-panels-white-background-1-1 {
  height: 622px;
  margin-left: 62.0px;
  margin-top: 78px;
  object-fit: cover;
  width: 980px;
}


img {
  width: auto;
  height: auto;
}

